import { OurWineMakersNote } from "./OurWineMakersNote"
import { WhatOthersSay  } from "./WhatOthersSay"
import { Products } from "./Products"
import { ProductDetails } from './ProductDetails'
import { Gallery } from './Gallery'

export const components = {
    our_wine_makers_note: OurWineMakersNote,
    instagram__or_gallery_: Gallery,
    what_others_say: WhatOthersSay,
    products: Products,
    product_details: ProductDetails,
}